import team from "../../images/team.svg";
import studio from "../../images/studio-4.webp";
import micro from "../../images/micro.webp";
import instrument from "../../images/studio-5.webp";
import Team from "../Team/index";

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Le studio',
  headline: 'Accès 24H/24, 7J/7',
  description: `
Le Studio RecSa a 3 minutes a peine du carré de soie accessible en voiture mais également en transports via la ligne Zi3
vous accueille dans un cadre idyllique pensé pour votre créativité et votre confort.
Nos équipes sont entièrement dédiée à la réalisation de haute qualité de vos projets musicaux et vidéos divers.
  `,
  buttonLabel: 'Get started',
  imgStart: false,
  img: micro,
  alt: 'car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Nos locaux',
  headline: 'Un accueil chaleureux',
  description: `
Le studio se compose d’une salle et cabine d’enregistrement dotées d’une acoustique irréprochable et
équipées de matériel haut de gamme.
Pour le confort de vos projet  que ce soi pour marquer un événement ( mariage naissance cover …)
un espace photo est disponible .Nous mettons également à votre disposition un espace  détente avec boisson
et snacking, Un parking privé est disponible avec emplacement réservé aux clients du studio.
  `,
  buttonLabel: 'Learn More',
  imgStart: true,
  img: studio,
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Nos préstations',
  headline: 'Un professionalisme au rendez-vous',
  description: `
  Le Studio RecSa et ses ingénieurs du son vous accueillent pour vos projets sonores, musicaux ou audiovisuels dans un espace chaleureux de 100 mètres carrés. De l'enregistrement au mixage, nous mettons notre expérience et notre matériel à votre disposition pour obtenir un résultat optimal. Prise de son, montage audio, mixage analogique et numérique, mastering numérique, découvrez nos prestations dans un studio professionnel.
  `,
  buttonLabel: 'Start Now',
  imgStart: false,
  img: instrument,
  alt: 'car',
  primary: false,
  darkText: true
};

export const homeObjFour = {
  id: 'team',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Notre équipe',
  headline: 'Pour vous accompagner',
  description: `
  Une équipe avec dix ans d'expérience dans le management est disponible pour vos promotions et communications sur différents supports tels que YouTube, Spotify, etc. Nous sommes disponibles 24h/24 et 7j/7 pour créer du contenu unique et professionnel.
  `,
  buttonLabel: 'Start Now',
  cards: (<Team />),
  imgStart: true,
  img: team,
  alt: 'team',
  primary: true,
  darkText: false
};
