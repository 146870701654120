import { HeroContainer, HeroContent, HeroP } from './HeroElements'
import studio from '../../images/studio.svg';
import mp4 from '../../videos/recsa.mp4';
import Video from './Video';
import { ArrowForward, ArrowRight } from './HeroElements';
import { HeroBtnWrapper } from './HeroElements';
import { Button } from '../ButtonElements';
import { useState } from 'react';
import { PopupModal } from "react-calendly";

const Hero = () => {
  const [hover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <Video src={mp4} />
      <HeroContent className="animated animatedFadeInUp fadeInUp">
        <img src={studio} alt="logo" style={{ maxHeight: "330px" }} />
        <HeroP>Votre nouveau studio d'enregistrement ouvre ses portes</HeroP>
        <HeroBtnWrapper>
          <Button onClick={() => setIsOpen(true)} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false' smooth="true" duration={500} spy="true" exact='true' offset={-80}>
            Réserver {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
      <PopupModal
        url="https://calendly.com/recsamusic/30min?hide_gdpr_banner=1"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </HeroContainer>
  )
}

export default Hero
