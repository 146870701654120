import React from 'react'
import { FaInstagram } from 'react-icons/fa'

import './main.css';

interface Props {
    image: string;
    description: string;
    instagram: string;
}

const Card: React.FC<Props> = ({ image, description, instagram }) => {
    return (
        <div style={{ display: "inline-block", position: "relative" }}>
            <div className='card'>
                <img src={image} alt="avatar" style={{ maxHeight: "160px", maxWidth: "40%", display: "block", borderRadius: "5px 0 0 5px" }} />
                <p style={{ paddingLeft: "10px", paddingRight: "10px", textAlign: "left" }}>
                    {description}
                </p>
                <div style={{ position: "absolute", bottom: "0px", right: "0" }}>
                    <FaInstagram onClick={() => window.open(instagram)} style={{fontSize: "20px", color: "#3D348B"  }}/>
                </div>
            </div>
        </div>
    );
};

export default Card;
