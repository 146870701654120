import Card from "../Card/index";
import adam from "../../images/adam.webp";
import dalil from "../../images/dalil.webp";
import mck from "../../images/mck.webp";
import { useEffect, useState } from "react";

const Team: React.FC = () => {
  const query = "(max-width: 450px)";
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    window
      .matchMedia(query)
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [matches]);

  return (
    <div style={{ textAlign: "center" }}>
      <Card
        image={dalil}
        instagram="https://instagram.com/laxda_beats?igshid=YmMyMTA2M2Y="
        description="Dalil, ingénieur du son expérimenté, travaille pour donner à la musique une dimension unique et cohérente."
      />
      <br />
      <Card
        image={mck}
        instagram="https://instagram.com/mc.inge?igshid=YmMyMTA2M2Y="
        description="MCK, chef d'équipe des ingénieurs du son avec 8 ans d'expérience, vous accompagne du début à la fin du processus de mixage et d'enregistrement."
      />
      <br />
      <Card
        image={adam}
        instagram="https://instagram.com/adam_ming69?igshid=YmMyMTA2M2Y="
        description="Adam, c'est plus de 5 ans d'expérience et plus de 300 clips réalisés. Son objectif, mettre en valeur les artistes."
      />
    </div>
  );
};

export default Team;
